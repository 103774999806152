import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getTokenURIAsync,
  selectAnimationDecoded,
  selectGetTokenURI,
  selectImageDecoded,
  selectResponseJson,
  selectTokenAnimation,
  selectTokenImage,
  selectTraits,
} from './tokenViewerSlice';
import styles from './TokenViewer.module.css';
import { Article, Button, Input, LoadingOrErrorOrContent } from '@cyberpnk/component-library';
import { useParams, useNavigate } from 'react-router-dom';

export function TokenViewer() {
  const dispatch = useAppDispatch();

  const params = useParams();
  const navigate = useNavigate();

  const getTokenURI = useAppSelector(selectGetTokenURI);
  const image = useAppSelector(selectTokenImage);
  const animation = useAppSelector(selectTokenAnimation);
  const responseJson = useAppSelector(selectResponseJson);
  const traits = useAppSelector(selectTraits);
  const imageDecoded = useAppSelector(selectImageDecoded);
  const animationDecoded = useAppSelector(selectAnimationDecoded);


  const traitsToDisplay = JSON.stringify(traits, null, 2);
  const responseJsonToDisplay = JSON.stringify(responseJson, null, 2);

  const [contractInputValue, setContractInputValue] = useState<string | undefined>(params.contract);
  const [tokenIdInputValue, setTokenIdInputValue] = useState<string | undefined>(params.tokenId);

  // const changeRoute = () => window.location.hash = `#/${contractInputValue}/${tokenIdInputValue}`;
  const changeRoute = () => {
    navigate(`/${contractInputValue}/${tokenIdInputValue}`);
    if (contractInputValue && tokenIdInputValue) {
      dispatch(getTokenURIAsync({ contractAddress: contractInputValue, tokenId: tokenIdInputValue }));
    }
  };

  return (
    <div className={styles.claim}>
      <Input placeholder={'Contract'} addressWidth={true} onChange={setContractInputValue} onEnter={changeRoute} value={contractInputValue} />{' '}
      <Input placeholder={'Token ID'} onChange={setTokenIdInputValue} onEnter={changeRoute} value={tokenIdInputValue} />{' '}
      <Button onClick={changeRoute}>Load</Button>
      <LoadingOrErrorOrContent loading={getTokenURI.loading} error={""} loadingText={"Loading token..."}>
        {getTokenURI.response ? <>
          {image ? <Article><h1>Image</h1><img alt={`Token #${getTokenURI.request.tokenId}`} src={image} /></Article> : null}
          {animation ? <Article><h1>Animation</h1><iframe title="Animation" src={animation}></iframe></Article> : null}
          {!image && !animation ? <Article>No image, no animation</Article> : null}

          <Article>
            <h1>Traits</h1>
            {traits ? <pre>{traitsToDisplay}</pre> : "No traits"}
          </Article>
          {responseJson ? <>
            <Article>
              <details>
                <summary>Response JSON</summary>
                <pre>{responseJsonToDisplay}</pre>
              </details>
            </Article>
          </> : null}
          {imageDecoded ? <>
            <Article>
              <details>
                <summary>Image SVG</summary>
                <pre>{imageDecoded}</pre>
              </details>
            </Article>
          </> : null}
        </>
          : null}
        {animationDecoded ? <>
          <Article>
            <details>
              <summary>Animation HTML</summary>
              <pre>{animationDecoded}</pre>
            </details>
          </Article>
        </> : null}
      </LoadingOrErrorOrContent>
    </div>
  );
}
