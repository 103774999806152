import {
  getErc721Contract,
} from "../../app/common";

declare global {
  interface Window { ethereum: any; }
}

export const getTokenURI = async ({ contractAddress, tokenId }: { contractAddress: string, tokenId: string }) => {
  const erc721 = await getErc721Contract(contractAddress);
  const result = await erc721.methods.tokenURI(tokenId).call();
  return result;
}
