import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
  connectWalletReducer,
  initWallet,
  initWalletEpic,
} from '@cyberpnk/component-library';
import { tokenViewerReducer } from '../features/TokenViewer/tokenViewerSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { loadTokenEpic, loadTokenFromUrlEpic, reloadTokenEpic } from '../features/TokenViewer/tokenViewerEpic';
import { routerNavigated } from './history';
import { createBrowserHistory } from 'history';


const epicMiddleware = createEpicMiddleware();

export const store = configureStore({
  reducer: {
    connectWallet: connectWalletReducer,
    tokenViewer: tokenViewerReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    return middleware.concat(epicMiddleware);
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const rootEpic = combineEpics(
  initWalletEpic,
  reloadTokenEpic,
  loadTokenFromUrlEpic,
  loadTokenEpic,
);

// @ts-ignore
epicMiddleware.run(rootEpic);

store.dispatch(initWallet());

const myHistory = createBrowserHistory()

// // Get the current location.
const location = myHistory.location;


// Listen for changes to the current location.
// returns "unlisten"
myHistory.listen(({ location }) => {
  store.dispatch(routerNavigated({ path: location.pathname }));
});

store.dispatch(routerNavigated({ path: location.pathname }));

export const history = myHistory;