import React from 'react';
import styles from './App.module.css';
import { useAppSelector, useAppDispatch } from './app/hooks';
import {
  Network, Footer,
  ConnectWallet, selectConnected,
  selectNetwork,
  TopBar,
  ConnectWalletStatus,
  isUnknownNetwork
} from '@cyberpnk/component-library';
import { version } from '../package.json';
import { TokenViewer } from './features/TokenViewer/TokenViewer';
import { Routes, Route } from "react-router-dom";

function App() {
  const connected = useAppSelector(selectConnected);
  const network: Network = useAppSelector(selectNetwork);

  const isKnownNetwork = !isUnknownNetwork(network);

  const properlyConnected = connected && isKnownNetwork;
  const showConnectStep = !properlyConnected;

  const showMain = properlyConnected;

  return (
    <>
      <div className={styles.App}>
        <TopBar>
          <ConnectWalletStatus useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
        </TopBar>
        <main>
          <h2>Token Viewer</h2>
          <h3>Dev tool to inspect the contents of tokenURI calls</h3>

          {showConnectStep ?
            <ConnectWallet useAppDispatch={useAppDispatch} useAppSelector={useAppSelector} />
            : null}

          {showMain ?
            <div className={styles.main}>
              <Routes>
                <Route path="/" element={<TokenViewer />} />
                <Route path="/:contract" element={<TokenViewer />} />
                <Route path="/:contract/:tokenId" element={<TokenViewer />} />
              </Routes>

            </div> : null}
        </main>
        <div className={styles.footer}>
          <Footer version={version} network={network} />
        </div>
      </div>
    </>
  );
}

export default App;
