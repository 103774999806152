import { ofType, StateObservable } from "redux-observable";
import { filter, map, switchMap } from "rxjs/operators";
import { getTokenURIAsync } from "./tokenViewerSlice";
import { RootState } from "../../app/store";
import { attemptAutoConnectAsync, connectWalletAsync } from "@cyberpnk/component-library";
import { routerNavigated } from "../../app/history";

export const reloadTokenEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(connectWalletAsync.fulfilled),
  map(() => state$.value.tokenViewer.getTokenURI.request),
  filter(request => !!request),
  switchMap(({ contractAddress, tokenId }) => [
    getTokenURIAsync({ contractAddress, tokenId })
  ]),
);

const paramsRegex = /\/?#\/?([^/]*)\/([^/]*)\/?/;

export const loadTokenEpic = (action$: any) => action$.pipe(
  ofType(connectWalletAsync.fulfilled, attemptAutoConnectAsync.fulfilled),
  map(() => {
    const [, contractAddress, tokenId] = (paramsRegex.exec(window.location.hash) || []);
    return {contractAddress, tokenId};
  }),
  filter(({ contractAddress, tokenId }) => !!contractAddress && !!tokenId),
  switchMap(({ contractAddress, tokenId }) => [
    getTokenURIAsync({ contractAddress, tokenId })
  ]),
);

export const loadTokenFromUrlEpic = (action$: any, state$: StateObservable<RootState>) => action$.pipe(
  ofType(routerNavigated),
  map(() => {
    const isConnected = state$.value.connectWallet.connected
    const [, contractAddress, tokenId] = (paramsRegex.exec(window.location.hash) || []);
    return { contractAddress, tokenId, isConnected };
  }),
  filter(({ contractAddress, tokenId, isConnected }) => isConnected && !!contractAddress && !!tokenId),
  switchMap(({ contractAddress, tokenId }) => [
    getTokenURIAsync({ contractAddress, tokenId })
  ]),
);



