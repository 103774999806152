import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  connectWalletAsync, newWalletConnected,
  SimpleAsyncState, walletDisconnected
} from '@cyberpnk/component-library';
import {
  getTokenURI
} from './tokenViewerApi';

export interface TokenViewerState {
  getTokenURI: SimpleAsyncState<string, GetTokenURIAsyncRequest>;
}

const initialState: TokenViewerState = {
  getTokenURI: {
    loading: false,
  },
};

export interface GetTokenURIAsyncRequest {
  contractAddress: string,
  tokenId: string
}

export const getTokenURIAsync = createAsyncThunk(
  'tokenViewer/getTokenURI',
  async ({ contractAddress, tokenId }: GetTokenURIAsyncRequest, thunkAPI) => {
    const result = await getTokenURI({ contractAddress, tokenId });
    return result;
  }
);

export const tokenViewerSlice = createSlice({
  name: 'tokenViewer',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTokenURIAsync.pending, (state, action) => {
        state.getTokenURI.loading = true;
        state.getTokenURI.error = undefined;
        state.getTokenURI.request = action.meta.arg;
      })
      .addCase(getTokenURIAsync.fulfilled, (state, action) => {
        state.getTokenURI.loading = false;
        state.getTokenURI.response = action.payload;
      })
      .addCase(getTokenURIAsync.rejected, (state, action) => {
        state.getTokenURI.loading = false;
        state.getTokenURI.error = "There was an error with the transaction"
      })

      .addCase(connectWalletAsync.pending, (state) => {
        state.getTokenURI = initialState.getTokenURI;
      })
      .addCase(newWalletConnected, (state) => {
        state.getTokenURI = initialState.getTokenURI;
      })
      .addCase(walletDisconnected, (state) => {
        state.getTokenURI = initialState.getTokenURI;
      })
  },
});


export const selectGetTokenURI = (state: any) => {
  return state.tokenViewer.getTokenURI
};

export const selectResponseJson = (state: any) => {
  const response = state.tokenViewer.getTokenURI.response;
  const BASE_64_JSON_PREFIX = "data:application/json;base64,";
  const JSON_PREFIX = "data:application/json,";
  const JSON_PREFIX_UTF8 = "data:application/json;utf8,";
  if (response?.startsWith(BASE_64_JSON_PREFIX)) {
    const jsonStr = window.atob(response.replace(BASE_64_JSON_PREFIX, ""));
    try {
      return JSON.parse(jsonStr);
    } catch (e) {
    }
  } else if (response?.startsWith(JSON_PREFIX) || response?.startsWith(JSON_PREFIX_UTF8)) {
    const jsonStr = decodeURIComponent(response.replace(JSON_PREFIX, "").replace(JSON_PREFIX_UTF8, ""));
    try {
      const result = JSON.parse(jsonStr);
      return result;
    } catch (e) {
    }
  }
}

export const selectTraits = (state: any) => {
  const responseJson = selectResponseJson(state);
  if (responseJson?.traits) {
    return responseJson?.traits;
  }
}

export const selectTokenImage = (state: any) => {
  const responseJson = selectResponseJson(state);
  if (responseJson?.image) {
    return responseJson?.image;
  }
}

export const selectTokenAnimation = (state: any) => {
  const responseJson = selectResponseJson(state);
  if (responseJson?.animation_url) {
    return responseJson?.animation_url;
  }
}


var prettifyXml = function (sourceXml: string) {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString([
    // describes how we want to modify the XML - indent everything
    '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
    '  <xsl:strip-space elements="*"/>',
    '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
    '    <xsl:value-of select="normalize-space(.)"/>',
    '  </xsl:template>',
    '  <xsl:template match="node()|@*">',
    '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
    '  </xsl:template>',
    '  <xsl:output indent="yes"/>',
    '</xsl:stylesheet>',
  ].join('\n'), 'application/xml');

  const xsltProcessor = new XSLTProcessor();

  xsltProcessor.importStylesheet(xsltDoc);

  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

export const selectImageDecoded = (state: any) => {
  const image = selectTokenImage(state);
  const BASE_64_SVG_PREFIX = "data:image/svg+xml;base64,"
  const SVG_PREFIX = "data:image/svg+xml,"
  if (image?.startsWith(BASE_64_SVG_PREFIX)) {
    const svgStr = window.atob(image.replace(BASE_64_SVG_PREFIX, ""));
    try {
      const pretty = prettifyXml(svgStr);
      return pretty;
    } catch (e) {
      return svgStr;
    }
  } else if (image?.startsWith(SVG_PREFIX)) {
    const svgStr = decodeURIComponent(image.replace(SVG_PREFIX, ""));
    try {
      const pretty = prettifyXml(svgStr);
      return pretty;
    } catch (e) {
      return svgStr;
    }
  }
}

export const selectAnimationDecoded = (state: any) => {
  const animation = selectTokenAnimation(state);
  const BASE_64_HTML_PREFIX = "data:text/html;base64,"
  const HTML_PREFIX = "data:text/html,"
  if (animation?.startsWith(BASE_64_HTML_PREFIX)) {
    const htmlStr = window.atob(animation.replace(BASE_64_HTML_PREFIX, ""));
    try {
      const pretty = prettifyXml(htmlStr);
      return pretty;
    } catch (e) {
      return htmlStr;
    }
  } else if (animation?.startsWith(HTML_PREFIX)) {
    const htmlStr = decodeURIComponent(animation.replace(HTML_PREFIX, ""));
    try {
      const pretty = prettifyXml(htmlStr);
      return pretty;
    } catch (e) {
      return htmlStr;
    }
  }
}



export const tokenViewerReducer = tokenViewerSlice.reducer;

export default tokenViewerSlice.reducer;
